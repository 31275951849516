<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-02 18:46:10
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 15:51:10
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="门店">
              <a-input v-model="searchData.storeName" placeholder="门店名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="员工">
              <a-input v-model="searchData.managerName" placeholder="员工名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
<!--        <a-button type="primary" @click="toHandler('staff')">申请临时配额</a-button>-->
<!--        <a-button type="primary" @click="onSummary" v-if="isSummary && (selectedRows.length > 0 && selectedRows[0].status== 2 ) && flagSendList && statusList">汇总</a-button>-->
<!--        <a-button-->
<!--          v-if="isSubmit && selectedRows.length == 1 && selectedRows[0].status == 0"-->
<!--          type="primary"-->
<!--          @click="toHandler('submit_audio')"-->
<!--          >提交审核</a-button-->
<!--        >-->
<!--        <a-button-->
<!--          v-if="isAudit && selectedRows.length == 1 && selectedRows[0].status == 1"-->
<!--          type="primary"-->
<!--          @click="toHandler('audio')"-->
<!--          >审核</a-button-->
<!--        >-->
        <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
<!--        <a-button v-if="isAudit && selectedRows.length > 0 && selectedRows[0].status == 1" type="default" @click="toHandler('rollbackStatus')" icon="rollback">撤审</a-button>-->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="status" slot-scope="text">
          <span> {{ text | status }} </span>
        </span>
        <span slot="summary" slot-scope="text">
          <span v-if="text == 1">未汇总</span>
          <span v-if="text == 2">已汇总</span>
        </span>
        <span slot="quotaStatus" slot-scope="text">
                <span v-if="text == 1">未过期</span>
                <span v-if="text == 2">已过期</span>
              </span>
        <span slot="expireDate" slot-scope="text,row">

           <span v-if="row.startingTime != null && row.expireDate">{{row.startingTime}} 至 {{row.expireDate}}</span>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerPreOrdersNum-edit-modal
        ref="DealerPreOrdersNumEditModal"
        @reload="getData"
      ></DealerPreOrdersNum-edit-modal>
      <!-- 审核配额 -->
      <AudioPageAll ref="AudioPage" @reload="getData"></AudioPageAll>
    </div>
  </a-card>
</template>

<script>
import { staffColumns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import { delDealerPreOrdersNum, staffQuotaList, rollbackStatus} from './api/DealerPreOrdersNumApi'
import AudioPageAll from './components/AudioPageAll.vue'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AudioPageAll,
  },
  data() {
    return {
      columns: staffColumns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: '',
      flagSendList: '',
      isSummary: checkPermission('allot:manager:quota:summary'),
      isSubmit: checkPermission('allot:manager:quota:submit'),
      isAudit: checkPermission('allot:manager:quota:audit'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          let arr = selectedRows.map((e) => {
            return e.confirmStatus
          })
          let arr1 = selectedRows.map((e) => {
          return e.status
        })
          this.statusList = arr.every((el) => el == arr[0])
        this.flagSendList = arr1.every((el) => el == arr1[0])
          console.log(11111, this.statusList)
        },
      }
    },
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '已审核待分配',
        3: '审核通过',
        4: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRows = []
      this.selectedRowKeys = []
      staffQuotaList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    // 汇总
    onSummary() {
      let arr = []
      for (let i = 0; i < this.selectedRows.length; i++) {
        if (arr.indexOf(this.selectedRows[i].storeName) === -1) {
          arr.push(this.selectedRows[i].storeName)
        }
      }
      if (arr.length > 1) {
        return this.$message.warning('请选择同一门店的进行汇总')
      }

      this.axios.post(`/api/base/quota/storeStaffApplicationRecord/employeeSummary`, {ids: this.selectedRowKeys}).then(res => {
        this.$message.success(res.message)
        this.getData()
      })
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] == record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'staff') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'staff')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'staff':
          _this.$refs.DealerPreOrdersNumEditModal.setRowData(_this.selectedRows[0], 'staff')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerPreOrdersNum(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'audio':
          _this.$refs.AudioPage.show(_this.selectedRows[0], 'staff')
          break
        case 'submit_audio':
          _this.$confirm({
            title: '确认提交审核？',
            onOk: () => {
              _this.axios
                .post(`/api/base/quota/storeStaffApplicationRecord/updateData`, {
                  status: 1,
                  id: this.selectedRows[0].id,
                  storeStaffId: this.selectedRows[0].storeStaffId
                })
                .then((res) => {
                  _this.$message.success(res.message)
                  _this.getData()
                })
                .catch((err) => {})
            },
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
